<template>
  <div class="m-wizard__head m-portlet__padding-x">
    <!--begin: Form Wizard Progress -->
    <div class="m-wizard__progress">
      <div class="progress">
        <div
          class="progress-bar w-0"
          role="progressbar"
        />
      </div>
    </div>
    <div class="m-wizard__nav">
      <div class="m-wizard__steps">
        <!-- STEP 0 WIZARD-COMPONENT -->
        <!-- ACTIVE -->
        <template v-if="firstStep">
          <div class="m-wizard__step m-wizard__step--current">
            <a 
              href="#" 
              class="m-wizard__step-number" 
              @click.prevent
            >
              <span>
                <font-awesome-icon    
                  icon="filter"
                  style="color: white; width: 24px; height: 24px;"
                /></span>
            </a>
            <div class="m-wizard__step-info">
              <div class="m-wizard__step-title">
                Filter
              </div>
              <div class="m-wizard__step-desc">
                Set filters
              </div>
            </div>
          </div>
        </template>
        <!-- INACTIVE -->
        <template v-else>
          <div
            class="m-wizard__step"
            @click.prevent="$emit('smsWizard_setStep', 0)"
          >
            <a
              href="#"
              class="m-wizard__step-number"
            >
              <span><font-awesome-icon    
                icon="filter"
                style="color: white; width: 24px; height: 24px;"
              /></span>
            </a>
            <div class="m-wizard__step-info">
              <div class="m-wizard__step-title">
                Filter
              </div>
              <div class="m-wizard__step-desc">
                Set filters
              </div>
            </div>
          </div>
        </template>
        <!-- STEP 1 WIZARD-COMPONENT -->
        <!-- ACTIVE -->
        <template v-if="secondStep">
          <div class="m-wizard__step m-wizard__step--current">
            <a
              href="#"
              class="m-wizard__step-number"
              @click.prevent
            >
              <span>        
                <font-awesome-icon
                  class="mr-1"
                  icon="envelope"
                  style="color: white; width: 24px; height: 24px;"
                />
              </span>
            </a>
            <div class="m-wizard__step-info">
              <div class="m-wizard__step-title">
                Message
              </div>
              <div class="m-wizard__step-desc">
                Choose a message
              </div>
            </div>
          </div>
        </template>
        <!-- INACTIVE -->
        <template v-else>
          <div
            class="m-wizard__step"
            @click.prevent
          >
            <a
              href="#"
              class="m-wizard__step-number"
            >
              <span>        
                <font-awesome-icon
                  class="mr-1"
                  icon="envelope"
                  style="color: white; width: 24px; height: 24px;"
                />
              </span>
            </a>
            <div class="m-wizard__step-info">
              <div class="m-wizard__step-title">
                Message
              </div>
              <div class="m-wizard__step-desc">
                Choose a message
              </div>
            </div>
          </div>
        </template>
        <!-- STEP 1 WIZARD-COMPONENT -->
        <!-- ACTIVE -->
        <template v-if="thirdStep">
          <div class="m-wizard__step m-wizard__step--current">
            <a
              href="#"
              class="m-wizard__step-number"
              @click.prevent
            >
              <span>        
                <font-awesome-icon
                  class="mr-1"
                  icon="check"
                  style="color: white; width: 24px; height: 24px;"
                />
              </span>
            </a>
            <div class="m-wizard__step-info">
              <div class="m-wizard__step-title">
                Confirmation
              </div>
              <div class="m-wizard__step-desc">
                Verify the message and recipients
              </div>
            </div>
          </div>
        </template>
        <!-- INACTIVE -->
        <template v-else>
          <div
            class="m-wizard__step"
            @click.prevent
          >
            <a
              href="#"
              class="m-wizard__step-number"
            >
              <span>        
                <font-awesome-icon
                  class="mr-1"
                  icon="check"
                  style="color: white; width: 24px; height: 24px;"
                />
              </span>
            </a>
            <div class="m-wizard__step-info">
              <div class="m-wizard__step-title">
                Confirmation
              </div>
              <div class="m-wizard__step-desc">
                Verify the message and recipients
              </div>
            </div>
          </div>
        </template>
        <!-- STEP 3 WIZARD-COMPONENT -->
        <!-- ACTIVE -->
        <template v-if="fourthStep">
          <div class="m-wizard__step m-wizard__step--current">
            <a 
              href="#" 
              class="m-wizard__step-number" 
              @click.prevent
            >
              <span><font-awesome-icon    
                icon="flag"
                style="color: white; width: 24px; height: 24px;"
              /></span>
            </a>
            <div class="m-wizard__step-info">
              <div class="m-wizard__step-title">
                Sending
              </div>
              <div class="m-wizard__step-desc">
                The message is being sent
              </div>
            </div>
          </div>
        </template>
        <!-- INACTIVE -->
        <template v-else>
          <div 
            class="m-wizard__step" 
            @click.prevent
          >
            <a 
              href="#" 
              class="m-wizard__step-number"
            >
              <span><font-awesome-icon    
                icon="flag"
                style="color: white; width: 24px; height: 24px;"
              /></span>
            </a>
            <div class="m-wizard__step-info">
              <div class="m-wizard__step-title">
                Sending
              </div>
              <div class="m-wizard__step-desc">
                The message is being sent
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerNotificationWizardNav",
  props: {
    firstStep: {
      type: Boolean,
      required: true
    },
    secondStep: {
      type: Boolean,
      required: true
    },
    thirdStep: {
      type: Boolean,
      required: true
    },
    fourthStep: {
      type: Boolean,
      required: true
    }
  }
}
</script>